export default class Queue {
  #arr;

  constructor(arr = []) {
    this.#arr = arr;
  }

  enqueue(item) {
    this.#arr.push(item);
  }

  dequeue() {
    return this.#arr.shift();
  }

  size() {
    return this.#arr.length;
  }

  isEmpty() {
    return this.#arr.length === 0;
  }
}

<template>
  <td
    v-bind="$_grid_cell_attributes"
    v-on="$_grid_cell_listeners"
    :class="$_grid_cell_class"
    :style="$_grid_cell_style"
  >
    <slot
      v-bind:record="record"
      v-bind:field="field"
      v-bind:rowIndex="rowIndex"
      v-bind:colIndex="colIndex"
      v-bind:dataType="dataType"
      v-bind:data="$_grid_cell_data"
      v-bind:result="result"
    >
      <div
        v-if="dataType === 'boolean'"
        class="f-checkbox"
        :class="{ checked: result === 'true' }"
      >
        <span class="f-icon" />
      </div>
      <template v-else>
        {{ result }}
      </template>
    </slot>
  </td>
</template>

<script>
import {
  getFormatter,
  RESULT_ERROR,
} from "@/components/grid/GridCellDataFormatUtil";

export default {
  name: "GridCell",
  props: {
    record: {
      type: Object,
      required: true,
    },
    field: {
      type: String,
    },
    iteratee: {
      type: [Function, String],
    },
    cellStyle: {
      type: [Function, Object],
      default: () => ({}),
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    colIndex: {
      type: Number,
      required: true,
    },
    cellClass: {
      type: [Function, Array, Object, String],
      default: () => ({}),
    },
    format: {
      type: [Function, String],
    },
    dataType: {
      type: String,
      required: true,
      validator(dataType) {
        return [
          "string",
          "number",
          "date",
          "boolean",
          "datetime",
          "time",
        ].includes(dataType.toLowerCase());
      },
    },
  },
  computed: {
    $_grid_cell_attributes() {
      return Object.assign({}, this.$attrs, {});
    },
    $_grid_cell_listeners() {
      return Object.assign({}, this.$listeners, {});
    },
    $_grid_cell_class() {
      let _class = {};

      if (this.cellClass instanceof Function) {
        const result = this.cellClass(
          this.record,
          this.rowIndex,
          this.colIndex
        );
        if (Array.isArray(result)) {
          result.forEach((cl) => {
            _class[cl] = true;
          });
        } else if (typeof result === "object") {
          _class = result;
        } else if (typeof result === "string") {
          _class[result] = true;
        }
      } else if (Array.isArray(this.cellClass)) {
        this.cellClass.forEach((cl) => {
          _class[cl] = true;
        });
      } else if (typeof this.cellClass === "object") {
        _class = this.cellClass;
      } else if (typeof this.cellClass === "string") {
        _class[this.cellClass] = true;
      }

      return _class;
    },
    $_grid_cell_style() {
      if (this.cellStyle instanceof Function) {
        return this.cellStyle(
          this.record,
          this.rowIndex,
          this.colIndex
        );
      } else {
        return this.cellStyle;
      }
    },
    $_grid_cell_data() {
      if (this.iteratee instanceof Function) {
        return this.iteratee(this.record, this.rowIndex, this.colIndex);
      } else if (typeof this.iteratee === "string") {
        return this.iteratee
          .split(".")
          .reduce(
            (object, key) => (object ? object[key] : undefined),
            this.record
          );
      } else {
        return this.field
          ?.split(".")
          ?.reduce(
            (object, key) => (object ? object[key] : undefined),
            this.record
          );
      }
    },
    result() {
      if (this.format === undefined || this.format === null) {
        if (
          this.$_grid_cell_data === undefined ||
          this.$_grid_cell_data === null
        ) {
          return "";
        } else {
          return `${this.$_grid_cell_data}`;
        }
      }

      if (this.format instanceof Function) {
        return this.format(this.$_grid_cell_data);
      } else if (typeof this.format === "string") {
        try {
          return getFormatter(this.format)(this.$_grid_cell_data);
        } catch (e) {
          return RESULT_ERROR;
        }
      } else {
        return `${this.$_grid_cell_data}`;
      }
    },
  },
};
</script>

<style scoped>
.f-checkbox {height: auto;line-height: 0;display: inline-block;}
.f-checkbox.checked .f-icon:before {display: block;width: 7px;height: 7px;margin: 2px;background: transparent url("../../assets/images/common/check-check.png") no-repeat center -28px;content: "";}
</style>

<template>
  <div
    class="f-grid-wrapper"
    ref="grid"
    v-bind="$attributes"
    @click="$_onGridBodyClick"
    @mousemove="$_onGridColumnResizerMouseMove"
    @mouseup="$_onGridColumnResizerMouseUp"
  >
    <div
      class="f-grid"
      :class="{
        'overflow-scroll': enableScrollbar,
      }"
    >
      <div v-if="visibleHeader" class="f-grid-header">
        <div ref="grid-header-content" class="f-content">
          <table class="f-table">
            <colgroup>
              <col
                ref="grid-header-colgroup-columns"
                v-for="(column, index) in leavesColumns"
                v-bind:key="`grid-${uid}-header-colgroup-col-${index}`"
                :style="{
                  minWidth: `${column.minWidth}px`,
                  width: `${column.width}px`,
                }"
              />
            </colgroup>
            <thead class="f-table-head">
              <tr
                v-for="(headerColumns, iHeaderRow) in headerColumnRows"
                v-bind:key="`grid-${uid}-headers-${iHeaderRow}`"
                class="f-table-head-row f-grid-header"
              >
                <grid-column
                  v-for="(column, index) in headerColumns"
                  v-bind:key="`grid-${uid}-headers-${iHeaderRow}-columns-${index}`"
                  class="f-table-head-header-column"
                  :ref="`grid-columns-${column.id}`"
                  :column="column"
                  @click="$_onColumnClick($event, { column, columnIndex: index })"
                >
                  <div class="f-grid-column-info">
                    <span
                      v-if="
                        visibleOrderPriority && $_getOrderPriority(column.field)
                      "
                      class="f-grid-column-order-priority"
                      >{{ $_getOrderPriority(column.field) }}</span
                    >
                    <span class="f-grid-column-name">
                      {{ column.name }}
                    </span>
                  </div>
                  <div
                    class="f-grid-column-order-direction"
                    :class="[$_getOrderDirection(column.field)]"
                  />
                  <div
                    v-if="
                      filterable &&
                      (!column.columns || !(0 < column.columns.length)) &&
                      column.field &&
                      column.type &&
                      column.filterable
                    "
                    class="f-grid-column-filter"
                    :class="{
                      apply: !(
                        filters.findIndex(
                          (filter) => filter.field === column.field
                        ) < 0
                      ),
                    }"
                    @click="$_onColumnFilterClick($event, { column })"
                  />
                  <div
                    v-if="column.resizable"
                    class="f-grid-column-resizer"
                    @mousedown="
                      column.resizable &&
                        $_onGridColumnResizerMouseDown($event, { column })
                    "
                  />
                </grid-column>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div
        class="f-grid-body"
        :style="$_grid_body_style"
        @keydown.capture="$_onGridBodyKeypress"
      >
        <div
          ref="grid-body-content"
          class="f-content"
          @scroll="$_onGridBodyContentScroll"
        >
          <table class="f-table">
            <colgroup>
              <col
                ref="grid-body-colgroup-columns"
                v-for="(column, index) in leavesColumns"
                v-bind:key="`grid-${uid}-body-colgroup-columns-${index}`"
                :style="{
                  minWidth: `${column.minWidth}px`,
                  width: `${column.width}px`,
                }"
              />
            </colgroup>
            <tbody class="f-table-body">
              <tr
                v-if="!$_grid_records || $_grid_records.length === 0"
                class="f-table-body-row f-grid-body-empty-row"
              >
                <td
                  class="f-table-body-data-column f-grid-cell"
                  :colspan="leavesColumns.length"
                >
                  No records to display
                </td>
              </tr>
              <tr
                v-else
                v-for="(record, rowIndex) in $_grid_records"
                v-bind:key="`grid-${uid}-rows-${rowIndex}`"
                class="f-table-body-row f-grid-row"
                :class="[
                  {
                    'f-grid-row-selected':
                      $_isInSelectedRow(record),
                  },
                ]"
              >
                <grid-cell
                  :ref="`grid-cells-${rowIndex}-${colIndex}`"
                  v-for="(column, colIndex) in leavesColumns"
                  v-bind:key="`grid-${uid}-cells-${colIndex}`"
                  class="f-table-body-data-column f-grid-cell"
                  :class="[
                    {
                      'f-grid-cell-selected': $_isSelectedCellIndex(
                        rowIndex,
                        colIndex
                      ),
                    },
                  ]"
                  :tabindex="
                    enableSelectCell && column.field !== '__row_number__'
                      ? $attributes.tabindex
                      : undefined
                  "
                  :field="column.field"
                  :cell-class="column.cellClass"
                  :cell-style="column.cellStyle"
                  :row-index="rowIndex"
                  :col-index="colIndex"
                  :record="record"
                  :iteratee="column.iteratee"
                  :format="column.format"
                  :data-type="column.type"
                  @mousedown="
                    $_onGridCellMousedown($event, {
                      column,
                      record,
                      rowIndex,
                      colIndex,
                    })
                  "
                  @mouseover="
                    $_onGridCellMouseover($event, {
                      column,
                      record,
                      rowIndex,
                      colIndex,
                    })
                  "
                  @mouseup="
                    $_onGridCellMouseup($event, {
                      column,
                      record,
                      rowIndex,
                      colIndex,
                    })
                  "
                  @click="
                    $_onGridCellClick($event, {
                      column,
                      record,
                      rowIndex,
                      colIndex,
                    })
                  "
                  @dblclick="
                    $_onGridCellDoubleClick($event, {
                      column,
                      record,
                      rowIndex,
                      colIndex,
                    })
                  "
                >
                  <template v-if="column.cellTemplate" v-slot="slotProps">
                    <component
                      :is="column.cellTemplate"
                      v-bind="slotProps"
                      v-on="column.cellTemplateListeners"
                    />
                  </template>
                </grid-cell>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="f-grid-footer">
        <div v-if="pageable && visiblePagination" class="f-grid-pagination">
          <template v-if="!(sizeOfRecordPerPage < countOfAllRecords)">
            <div class="f-pager-first disable" />
            <div class="f-pager-prev disable" />
            <div class="f-pager">
              <div>
                <button class="f-page current" type="button">1</button>
              </div>
            </div>
            <div class="f-pager-next disable" />
            <div class="f-pager-last disable" />
          </template>
          <template v-else>
            <div
              class="f-pager-first"
              :class="{ disable: currentPageNumber === 1 }"
              @click="$_onGotoFirstPageClick"
            />
            <div
              class="f-pager-prev"
              :class="{ disable: currentPageNumber === 1 }"
              @click="$_onPreviousPageClick"
            />
            <div class="f-pager">
              <div v-if="hasPrevRange">
                <button
                  class="f-page"
                  type="button"
                  @click="$_onPreviousRangeClick"
                >
                  ...
                </button>
              </div>
              <div
                v-for="buttonIndex in endPageNumber - pageOffset"
                v-bind:key="`grid-${uid}-pagination-page-${buttonIndex}`"
              >
                <button
                  type="button"
                  class="f-page"
                  :class="{
                    current: currentPageNumber === pageOffset + buttonIndex,
                  }"
                  @click="
                    $_onPageClick($event, { page: pageOffset + buttonIndex })
                  "
                >
                  {{ pageOffset + buttonIndex }}
                </button>
              </div>
              <div v-if="hasNextRange">
                <button
                  type="button"
                  class="f-page"
                  @click="$_onNextRangeClick"
                >
                  ...
                </button>
              </div>
            </div>
            <div
              class="f-pager-next"
              :class="{ disable: currentPageNumber === lastPageNumber }"
              @click="$_onNextPageClick"
            />
            <div
              class="f-pager-last"
              :class="{ disable: currentPageNumber === lastPageNumber }"
              @click="$_onGotoLastPageClick"
            />
          </template>
          <div class="f-grid-pagination-summation">
            {{ currentPageNumber }} of {{ lastPageNumber }} pages ({{
              countOfAllRecords
            }}
            items)
          </div>
        </div>
      </div>
      <grid-column-filter-dialog
        v-if="visibleColumnFilterDialog"
        ref="grid-column-filter-dialog"
        class="f-grid-column-filter-dialog"
        :style="{
          top: `${
            headerColumnRows.length * 24 - (headerColumnRows.length + 1)
          }px`,
          left: `${absoluteLeftGridColumnFilterDialog}px`,
        }"
        @confirm="$_onColumnFilterDialogConfirm"
        @clear="$_onColumnFilterDialogClear"
      />
      <div
        ref="grid-resizer-guide"
        class="f-grid-column-resizer-guide"
        :style="{
          display: !resizeColumnController.active ? 'none' : 'block',
          left: `${resizeColumnController.startX}px`,
        }"
      />
    </div>
  </div>
</template>

<style scoped>
.f-grid-wrapper .f-grid {position: relative;display: block;width: 100%;height: 100%;border-color: #ccc;border-radius: 0;border-style: none solid solid;border-width: 1px;font-size: 13px;font-weight: normal;font-family: "돋움", Dotum, Arial, Verdana, sans-serif;}
.f-grid,
.f-grid-wrapper .f-grid [class^="f-"] {box-sizing: border-box;}
.f-grid-wrapper .f-grid .f-grid-header .f-content,
.f-grid-wrapper .f-grid .f-grid-body .f-content,
.f-grid-wrapper .f-grid .f-grid-footer .f-content {position: relative;}
.f-grid-wrapper .f-grid .f-grid-header .f-content {overflow: hidden;border-width: 0 1px 0 0;border-color: #e0e0e0;border-style: solid;}
.f-grid-wrapper .f-grid .f-grid-body .f-content {height: 100%;overflow: hidden;}
.f-grid-wrapper .f-grid.overflow-scroll .f-grid-body .f-content {overflow: scroll;}
.f-grid-wrapper .f-grid > div:first-child {border-top: 1px solid #000;}
.f-grid-wrapper .f-grid .f-grid-header {background-color: #f9f9f9;color: #000;vertical-align: middle;border-bottom-color: #e0e0e0;border-bottom-style: solid;border-bottom-width: 1px;}
.f-grid-wrapper .f-grid.overflow-scroll .f-grid-header {padding-right: 16px;}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header,
.f-grid-wrapper .f-grid .f-grid-body .f-grid-row {height: 24px;}
.f-grid-wrapper .f-grid .f-grid-column-resizer-guide {position: absolute;width: 1px;height: calc(100% - 57px);top: 1px;background-color: #000;cursor: col-resize;opacity: 1;}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header >>> .f-grid-column {padding: 0 8px;border-color: #e0e0e0;background-color: #f9f9f9;vertical-align: middle;border-width: 0 0 0 1px;border-style: solid;border-top: 0 none;height: auto;user-select: none;box-sizing: border-box;font-size: 12px;font-weight: 500;position: relative;text-align: left;}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header >>> .f-grid-column.sortable {cursor: pointer;}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header >>> .f-grid-column .f-grid-column-resizer {height: 100%;position: absolute;right: 0;top: 0;width: 4px;cursor: col-resize;}
.f-grid-wrapper .f-grid .f-grid-header >>> .f-grid-column:not(.f-grid-column-leaf) {border-bottom-style: solid;border-bottom-width: 1px;}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header >>> .f-grid-column:first-child {border-left-width: 0;}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header:not(:first-child) >>> .f-grid-column:first-child {border-left-width: 1px;}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header >>> .f-grid-column .f-grid-column-info {padding: 0 8px;margin: -8px;text-align: center !important;height: auto;text-overflow: ellipsis;white-space: nowrap;border: 0 none;display: block;font-size: 12px;font-weight: 500;line-height: 30px;user-select: none;overflow: hidden;}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header .f-grid-column >>> .f-grid-column-info.sortable {cursor: pointer;}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header >>> .f-grid-column .f-grid-column-info .f-grid-column-order-priority {background-color: #bbbdc0;color: #000;border-radius: 65%;display: inline-block;float: right;font-size: 9px;height: 15px;line-height: 16px;margin: 8px 5px 0 2px;text-align: center;width: 15px;}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header >>> .f-grid-column .f-grid-column-order-direction {float: right;width: 8px;height: 24px;padding: 0;margin: -19px 12px -19px 0;}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header >>> .f-grid-column .f-grid-column-order-direction.asc:before,
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header >>> .f-grid-column .f-grid-column-order-direction.desc:before {display: block;width: 8px;height: 8px;margin: 8px 0;content: "";}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header >>> .f-grid-column .f-grid-column-order-direction.asc:before {background: transparent url("../../assets/images/common/grid-sort-ascending.png") no-repeat center center;}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header >>> .f-grid-column .f-grid-column-order-direction.desc:before {background: transparent url("../../assets/images/common/grid-sort-descending.png") no-repeat center center;}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header .f-grid-column .f-grid-column-filter {float: right;text-align: right;width: 16px;height: 24px;padding: 0;margin: -19px -4px -19px 0;}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header >>> .f-grid-column .f-grid-column-filter:after {display: block;width: 8px;height: 8px;margin: 8px 4px;background: transparent url("../../assets/images/common/grid-filter.png") no-repeat center center;content: "";}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header >>> .f-grid-column .f-grid-column-filter.apply:after {background: transparent url("../../assets/images/common/grid-filter-active.png") no-repeat center center;}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header >>> .f-grid-column .f-grid-column-filter:hover {cursor: pointer;}
.f-grid-wrapper .f-grid .f-grid-header .f-grid-header >>> .f-grid-column.dev-click-area {background-image: url('../../assets/images/common/popup-open.png');background-repeat: no-repeat;background-position: 5px calc(50% - 1px);}
.f-grid-wrapper .f-grid .f-grid-body {background-color: #fff;vertical-align: middle;}
.f-grid-wrapper .f-grid .f-grid-body .f-grid-row:first-child .f-grid-cell {border-top: 0;}
.f-grid-wrapper .f-grid .f-grid-body .f-grid-row .f-grid-cell.f-grid-row-number {text-align: center;cursor: pointer;background-color: #f9f9f9;}
.f-grid-wrapper .f-grid .f-grid-body .f-grid-row.f-grid-row-selected .f-grid-cell.f-grid-row-number {background-color: #2e7458;color: #fff;}
.f-grid-wrapper .f-grid .f-grid-body .f-grid-row .f-grid-cell {border-style: solid;text-overflow: ellipsis;white-space: nowrap;padding: 0 8px;border-width: 1px 0 0 1px;border-color: #e0e0e0;color: #666;font-size: 12px;opacity: 1;overflow: hidden;outline: none;}
.f-grid-wrapper:focus-within .f-grid .f-grid-body .f-grid-row .f-grid-cell:focus,
.f-grid-wrapper:focus-within .f-grid .f-grid-body .f-grid-row .f-grid-cell.f-grid-cell-selected:focus {box-shadow: 0 0 0 2px #2e7458 inset;}
.f-grid-wrapper:focus-within .f-grid .f-grid-body .f-grid-row .f-grid-cell.f-grid-cell-selected:not(:focus) {box-shadow: 0 0 0 2px #fff inset;background-color: #ccc;}
.f-grid-wrapper .f-grid .f-grid-body .f-grid-row .f-grid-cell.dev-click-area:hover,
.f-grid-wrapper .f-grid .f-grid-body .f-grid-row .f-grid-cell.dev-click-area:focus {background-image: url("../../assets/images/common/popup-open.png");background-repeat: no-repeat;background-position: calc(100% - 5px) calc(50% - 1px);cursor: pointer;}
.f-grid-wrapper .f-grid .f-grid-body .f-grid-row .f-grid-cell.dev-click-area[style="text-align: right;"]:hover {background-position-x: 5px;}
.f-grid-wrapper .f-grid .f-grid-body .f-grid-row:last-child .f-grid-cell {border-bottom: 1px solid #e0e0e0;}
.f-grid-wrapper .f-grid .f-grid-body .f-grid-row:hover .f-grid-cell {background-color: #f0f0f0;}
.f-grid-wrapper .f-grid .f-grid-body .f-grid-row .f-grid-cell:first-child {border-left-width: 0;}
.f-grid-wrapper .f-grid .f-grid-body .f-grid-body-empty-row {color: #bbb;opacity: 1;}
.f-grid-wrapper .f-grid .f-grid-body .f-grid-body-empty-row .f-grid-cell {padding: 9px 12px 8px 12px;line-height: 1.5;}
.f-grid-wrapper:focus-within .f-grid .f-grid-body .f-grid-row:focus-within,
.f-grid-wrapper .f-grid .f-grid-body .f-grid-row.f-grid-row-selected {background-color: rgba(200, 240, 210, .75);}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination {overflow: visible;margin: 9px 1px;background-color: #fff;border-color: #e0e0e0;border-radius: 4px;border-style: solid;border-width: 0;display: inline-block;font-size: 0;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination div {display: inline;-webkit-user-select: none;-ms-user-select: none;user-select: none;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-first {margin: 0 0 0 3px !important;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-last {margin: 0 3px 0 0 !important;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-prev {margin: 0 !important;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-next {margin: 0 !important;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-first,
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-prev,
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-next,
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-last {border-right-style: solid;border-right-width: 0;display: inline-block;padding: 11px 9px 8px;border-right-color: #e0e0e0;background-color: #fff;user-select: none;min-width: auto;font-style: normal;font-variant: normal;font-weight: 400;line-height: 1;text-transform: none;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-first:before,
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-prev:before,
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-next:before,
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-last:before {display: inline-block;width: 6px;height: 8px;background: transparent no-repeat 50%;content: "";}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-first:hover,
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-prev:hover,
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-next:hover,
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-last:hover {border-radius: 3px;background-color: #f0f0f0;cursor: pointer;text-decoration: none;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-first.disable,
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-prev.disable,
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-next.disable,
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-last.disable {color: unset;opacity: 0.3;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-first.disable:hover,
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-prev.disable:hover,
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-next.disable:hover,
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-last.disable:hover {background-color: transparent;cursor: initial;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-first {padding: 5px 7px 6px 8px;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-first:before {background-image: url("../../assets/images/common/paging-first.png");}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-prev {padding: 5px 7px 6px 8px;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-prev:before {background-image: url("../../assets/images/common/paging-prev.png");}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-next {padding: 5px 8px 6px 7px;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-next:before {background-image: url("../../assets/images/common/paging-next.png");}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-last {padding: 5px 7px 6px 8px;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager-last:before {background-image: url("../../assets/images/common/paging-last.png");}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager .f-page {-webkit-box-sizing: border-box;box-sizing: border-box;-webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.03);box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.03);min-width: 21px;padding: 4px 6px 3px;margin: 0 3px;border: 1px solid #ccc;border-radius: 3px;color: #666;font-size: 12px;opacity: 1;cursor: pointer;text-decoration: none;display: inline-block;line-height: 1;text-align: center;background: #fff;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager .f-page.current {border-color: #2e7458;background-color: #2e7458;color: #fff;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination .f-pager .f-page.current:hover {border-color: #174e35;background-color: #174e35;}
.f-grid-wrapper .f-grid .f-grid-footer .f-grid-pagination-summation {position: absolute;display: inline;right: 0;padding: 1px 12px;font-size: 13px;font-weight: normal;color: #000;}
.f-grid-wrapper .f-grid .f-grid-column-filter-dialog {margin: 5px 3px 0 0;border: 1px solid #ccc;border-radius: 0;display: inline-flex;flex-direction: column;font-size: 12px;min-width: 250px;min-height: 72px;max-width: 100%;max-height: 350px;width: 250px;height: auto;position: absolute;background-color: #fff;box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.06);z-index: 100001;top: 20px;left: 197px;}
.f-table {border-spacing: 0;background-color: #fff;border-collapse: separate;table-layout: fixed;width: 100%;}
</style>

<script>
import { v4 as createUUID } from "uuid";
import { getLeavesOfColumns, getTableHeaderOfColumns } from "@/utils/dom/table";
import GridCell from "@/components/grid/GridCell";
import GridColumnFilterDialog from "@/components/grid/GridColumnFilterDialog";
import GridColumn from "@/components/grid/GridColumn";
import {
  FORMAT_DATE_YYYY_MM_DD,
  FORMAT_DATETIME,
  FORMAT_TIME_HH_mm,
} from "@/components/grid/GridCellDataFormatUtil";

export default {
  name: "Grid",
  components: { GridColumn, GridColumnFilterDialog, GridCell },
  props: {
    visibleRowNumberColumn: {
      type: Boolean,
      default: () => true,
    },
    visibleHeader: {
      type: Boolean,
      default: () => true,
    },
    rowIdentifier: {
      type: [String, Function],
      default: () => "id",
    },
    columns: {
      type: Array,
      required: true,
    },
    columnResizeable: {
      type: Boolean,
      default: () => true,
    },
    records: {
      type: Array,
      default: () => [],
    },
    sortable: {
      type: Boolean,
      default: () => true,
    },
    filterable: {
      type: Boolean,
      default: () => true,
    },
    pageable: {
      type: Boolean,
      default: () => true,
    },
    visiblePagination: {
      type: Boolean,
      default: () => true,
    },
    sizeOfRecordPerPage: {
      type: Number,
      default: () => 50,
    },
    countOfAllRecords: {
      type: Number,
      default: () => 0,
    },
    rangeOfPages: {
      type: Number,
      default: () => 10,
    },
    enableSelectCell: {
      type: Boolean,
      default: () => true,
    },
    enableMultiSelectCells: {
      type: Boolean,
      default: () => false,
    },
    enableMultiSelectRows: {
      type: Boolean,
      default: () => false,
    },
    enableScrollbar: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      uid: createUUID(),

      activePosition: this.visibleRowNumberColumn ? 1 : 0,
      registeredPosition: this.visibleRowNumberColumn ? 1 : 0,

      mousedownStartPosition: null,

      resizeColumnController: {
        active: false,
        index: -1,
        startX: null,
        startWidth: -1,
      },

      visibleOrderPriority: false,
      orders: [
        // {
        //   field: "",
        //   direction: "asc" // asc, desc
        // }
      ],
      visibleColumnFilterDialog: false,
      absoluteLeftGridColumnFilterDialog: 20,
      filters: [
        // {
        //   field: "",
        //   condition: "", // startsWith, endsWith, contains, equals, notEquals, includes, notIncludes
        //   q: "",
        //   type: "String"
        // }
      ],

      selectedRows: [],
      selectedCellIndexes: this.enableSelectCell
        ? [{ rowIndex: 0, colIndex: this.visibleRowNumberColumn ? 1 : 0 }]
        : [],
      currentPageNumber: 1,
    };
  },
  computed: {
    $attributes() {
      const classes = ["body-grid"];
      if (typeof this.$attrs.class === "string") {
        classes.push(this.$attrs.class);
      } else if (Array.isArray(this.$attrs.class)) {
        classes.concat(this.$attrs.class);
      } else if (typeof this.$attrs.class === "object") {
        classes.push(this.$attrs.class);
      }

      return Object.assign({}, this.$attrs, {
        class: classes,
        tabindex: this.$attrs.tabindex || 0,
      });
    },
    $_grid_filters() {
      return this.filters.map((filter) => {
        const columnPostFilter =
          this.columns.find(({ field }) => field === filter.field)
            ?.postFilter ||
          function (param) {
            return param;
          };

        return columnPostFilter(filter);
      });
    },
    $_grid_columns() {
      const defaultColumnMapper = (column) => ({
        ...column,
        resizable:
          column.resizable !== undefined && column.resizable !== null
            ? column.resizable
            : this.columnResizeable,
        sortable:
          column.sortable !== undefined && column.sortable !== null
            ? column.sortable
            : this.sortable,
        filterable:
          column.filterable !== undefined && column.filterable !== null
            ? column.filterable
            : this.filterable,
        format:
          column.format !== undefined && column.format !== null
            ? column.format
            : ((columnType) => {
                const typeString = (typeof columnType === "function"
                  ? columnType.name
                  : columnType
                ).toLowerCase();
                switch (typeString) {
                  case "date":
                    return FORMAT_DATE_YYYY_MM_DD;
                  case "time":
                    return FORMAT_TIME_HH_mm;
                  case "datetime":
                    return FORMAT_DATETIME;
                }
              })(column.type),
        type:
          column.type !== undefined && column.format !== null
            ? (typeof column.type === "function"
                ? column.type.name
                : column.type
              ).toLowerCase()
            : "string",
        id: createUUID(),
      });

      const recursiveDefaultColumnMapper = (column) => {
        if (
          column.columns &&
          Array.isArray(column.columns) &&
          0 < column.columns.length
        ) {
          column.columns = column.columns
            .map(recursiveDefaultColumnMapper)
            .filter(({ visible = true }) => visible);

          return column;
        } else {
          return defaultColumnMapper(column);
        }
      };

      return [
        {
          iteratee: "__row_number__",
          field: "__row_number__",
          type: Number,
          minWidth: 50,
          name: "NO",
          width: 50,
          sortable: false,
          filterable: false,
          cellClass: ["f-grid-row-number"],
          visible: this.visibleRowNumberColumn,
          resizable: false,
        },
        ...this.columns,
      ]
        .map(recursiveDefaultColumnMapper)
        .filter(({ visible = true }) => visible);
    },
    $_grid_records() {
      return (!this.visibleRowNumberColumn
        ? this.records
        : this.records.map((record, index) => ({
            ...record,
            __row_id__: ((this.rowIdentifier instanceof Function) ? this.rowIdentifier(record) : record[this.rowIdentifier]) || index,
            __row_number__:
              (this.currentPageNumber - 1) * this.sizeOfRecordPerPage +
              (index + 1),
          }))
      ).filter((column, index) =>
        this.pageable ? index < this.sizeOfRecordPerPage : true
      );
    },
    $_grid_body_style() {
      const gridHeaderHeightPixel = this.visibleHeader
        ? this.headerColumnRows.length * 24
        : 0;
      const gridPaginationHeightPixel =
        this.pageable && this.visiblePagination ? 39 : 0;

      return {
        height: `calc(100% - ${
          gridHeaderHeightPixel + gridPaginationHeightPixel + 1
        }px)`,
      };
    },
    $_selectedRowIds() {
      return this.selectedRows.map(({ __row_id__ }) => __row_id__);
    },
    headerColumnRows() {
      return getTableHeaderOfColumns(this.$_grid_columns);
    },
    leavesColumns() {
      return getLeavesOfColumns(this.$_grid_columns);
    },
    pageOffset() {
      return (
        this.currentPageNumber -
        1 -
        ((this.currentPageNumber - 1) % this.rangeOfPages)
      );
    },
    startPageNumber() {
      return this.pageOffset + 1;
    },
    endPageNumber() {
      const offsetLast = this.pageOffset + this.rangeOfPages;
      return this.lastPageNumber < offsetLast
        ? this.lastPageNumber
        : offsetLast;
    },
    lastPageNumber() {
      const mod = this.countOfAllRecords % this.sizeOfRecordPerPage;
      return (
        (this.countOfAllRecords - mod) / this.sizeOfRecordPerPage +
        (0 < mod ? 1 : 0)
      );
    },
    hasPrevRange() {
      return this.rangeOfPages < this.currentPageNumber;
    },
    hasNextRange() {
      return this.pageOffset + this.rangeOfPages < this.lastPageNumber;
    },
    ordersAndFiltersAndPage() {
      return {
        orders: this.orders,
        filters: this.$_grid_filters,
        page: this.currentPageNumber,
      };
    },
  },
  methods: {
    // public methods
    getColumns() {
      return JSON.parse(JSON.stringify(this.$_grid_columns)).filter(
        (column) => column.field !== "__row_number__"
      );
    },
    getRecords() {
      return JSON.parse(JSON.stringify(this.$_grid_records)).map((record) => {
        delete record["__row_number__"];
        return record;
      });
    },
    getActiveCell() {
      const rowIndex =
        (this.activePosition -
          (this.activePosition % this.leavesColumns.length)) /
        this.leavesColumns.length;
      const colIndex = this.activePosition % this.leavesColumns.length;
      const record = this.$_grid_records[rowIndex];
      const column = this.leavesColumns[colIndex];

      return {
        rowIndex,
        colIndex,
        record,
        column,
      };
    },
    getSelectedRows() {
      return this.selectedRows;
    },
    getOrdersAndFiltersAndPage() {
      return JSON.parse(JSON.stringify(this.ordersAndFiltersAndPage));
    },
    selectCurrentPageRows() {
      this.selectedRows = this.selectedRows.concat(this.$_grid_records);
    },
    deselectCurrentPageRows() {
      this.selectedRows = this.selectedRows
          .filter(({ __row_id__ }) =>
              !this.$_grid_records.map(({ __row_id__ }) => __row_id__).includes(__row_id__)
          );
    },
    clearAllSelectedRows() {
      this.selectedRows = [];
    },
    selectRow(rowIndex, adding = false) {
      if (typeof rowIndex !== "number") {
        throw Error("Invalid Parameter");
      }
      if (this.$_isRowIndexOutOfRangeInRows(rowIndex)) {
        throw Error("Out of Index");
      }

      const row = this.$_grid_records[rowIndex];
      if (!row) {
        return;
      }

      if (!adding) {
        this.selectedRows = [row];
      } else {
        // 선택한 순서를 유지함
        this.selectedRows = this.selectedRows
          .filter((selectedRow) => selectedRow.__row_id__ !== row.__row_id__)
          .concat(row);
      }
    },
    toggleRowSelection(rowIndex, adding = false) {
      if (typeof rowIndex !== "number") {
        throw Error("Invalid Parameter");
      }
      if (this.$_isRowIndexOutOfRangeInRows(rowIndex)) {
        throw Error("Out of Index");
      }

      const row = this.$_grid_records[rowIndex];
      if (!row) {
        return;
      }

      if (this.$_selectedRowIds.includes(row.__row_id__)) {
        this.selectedRows = this.selectedRows.filter(({__row_id__}) => __row_id__ !== row.__row_id__);
      } else {
        if (!adding) {
          this.selectedRows = [row];
        } else {
          // 선택한 순서를 유지함
          this.selectedRows = this.selectedRows
              .filter((selectedRow) => selectedRow.__row_id__ !== row.__row_id__)
              .concat(row);
        }
      }
    },
    selectRows(rowIndexes = [], adding = false) {
      if (rowIndexes.find((rowIndex) => typeof rowIndex !== "number")) {
        throw Error("Invalid Parameter");
      }
      if (
        rowIndexes.find((rowIndex) =>
          this.$_isRowIndexOutOfRangeInRows(rowIndex)
        )
      ) {
        throw Error("Out of Index");
      }

      const rows = this.$_grid_records
          .filter((record, index) => rowIndexes.includes(index));

      if (!rows || rows.length === 0) {
        return;
      }

      if (!adding) {
        this.selectedRows = rows;
      } else {
        // 선택한 순서를 유지함
        this.selectedRows = this.selectedRows
          .filter((selectedRow) => !rows.map(({__row_id__}) => __row_id__).includes(selectedRow.__row_id__))
          .concat(rows);
      }
    },
    selectCell(rowIndex, colIndex, adding = false) {
      if (!this.enableSelectCell) {
        return;
      }

      if (typeof rowIndex !== "number" || typeof colIndex !== "number") {
        throw Error("Invalid Parameter");
      }
      if (rowIndex < 0 || this.$_grid_records.length - 1 < rowIndex) {
        throw Error("Out of Index");
      }
      if (colIndex < 0 || this.leavesColumns.length - 1 < colIndex) {
        throw Error("Out of Index");
      }
      if (this.visibleRowNumberColumn && colIndex === 0) {
        throw Error("Out of Index");
      }

      if (!adding) {
        this.selectedCellIndexes = [
          {
            rowIndex,
            colIndex,
          },
        ];
      } else {
        // 선택한 순서를 유지함
        this.selectedCellIndexes = this.selectedCellIndexes
          .filter(
            (selectedCellIndex) =>
              !(
                selectedCellIndex.rowIndex === rowIndex &&
                selectedCellIndex.colIndex === colIndex
              )
          )
          .concat({
            rowIndex,
            colIndex,
          });
      }

      this.$_activateCell(rowIndex, colIndex);
    },
    gotoPage(event, { page }) {
      this.currentPageNumber = page;

      this.$emit("paged", event, this.getOrdersAndFiltersAndPage());
    },
    resetOrders() {
      this.orders = [];

      this.$emit("sorted", null, this.getOrdersAndFiltersAndPage());
    },
    resetFilters() {
      this.filters = [];

      this.$emit("filtered", null, this.getOrdersAndFiltersAndPage());
    },
    resetPage() {
      this.gotoPage(null, { page: 1 });
    },
    toggleCurrentPageRowsSelection() {
      // TODO
    },

    /* private controls of orders */
    $_getOrderPriority(field) {
      const index = this.orders.findIndex((order) => order.field === field);
      return index < 0 ? null : index + 1;
    },
    $_getOrderDirection(field) {
      return this.orders.find((order) => order.field === field)?.direction;
    },

    /* private controls of filters */
    /* private controls of pages */
    /* private controls of records */

    /* private controls of rows */
    $_isInSelectedRow({ __row_id__ }) {
      return this.$_selectedRowIds.includes(__row_id__);
    },
    $_isRowIndexOutOfRangeInRows(rowIndex) {
      return rowIndex < 0 || this.$_grid_records.length - 1 < rowIndex;
    },
    $_flushSelectedRows() {
      if (0 < this.selectedRows.length) {
        this.selectedRows = [];
      }
    },
    $_getLatestSelectRowId() {
      if (this.selectedRows.length === 0) {
        return;
      }

      return this.selectedRows[this.selectedRows.length - 1];
    },
    $_getLatestSelectRowIndex() {
      const latestSelectRowId = this.$_getLatestSelectRowId();
      if (!latestSelectRowId) {
        return -1;
      }

      return this.$_grid_records.findIndex(({__row_id__}) => __row_id__ === latestSelectRowId);
    },

    /* private controls of columns */
    $_isNumberColumnIndex(colIndex) {
      return this.visibleRowNumberColumn && colIndex === 0;
    },

    /* private controls of cells */
    $_isSelectedCellIndex(rowIndex, colIndex) {
      return !!this.selectedCellIndexes.find(
        (selectedCellIndex) =>
          selectedCellIndex.rowIndex === rowIndex &&
          selectedCellIndex.colIndex === colIndex
      );
    },
    $_flushSelectedCellIndexes() {
      if (0 < this.selectedCellIndexes.length) {
        this.selectedCellIndexes = [];
      }
    },
    $_refreshSelectedCellIndexes() {
      if (this.enableSelectCell && this.selectedCellIndexes.length === 0) {
        const rowIndex =
          (this.activePosition -
            (this.activePosition % this.leavesColumns.length)) /
          this.leavesColumns.length;
        const colIndex = this.activePosition % this.leavesColumns.length;

        this.selectedCellIndexes = [
          {
            rowIndex,
            colIndex,
          },
        ];
      }
    },
    $_activateCell(rowIndex, colIndex) {
      // cannot activate number column
      if (this.$_isNumberColumnIndex(colIndex)) {
        return;
      }

      // check range
      const position = rowIndex * this.leavesColumns.length + colIndex;
      if (this.$_isPositionOutOfRangeInCells(position)) {
        return;
      }

      const el = this.$refs[`grid-cells-${rowIndex}-${colIndex}`][0].$vnode.elm;
      el.focus();
      el.scrollIntoView({ block: "nearest" });

      this.activePosition = position;
    },
    $_getRegisteredCell() {
      const rowIndex =
        (this.registeredPosition -
          (this.registeredPosition % this.leavesColumns.length)) /
        this.leavesColumns.length;
      const colIndex = this.registeredPosition % this.leavesColumns.length;
      const record = this.$_grid_records[rowIndex];
      const column = this.leavesColumns[colIndex];

      return {
        rowIndex,
        colIndex,
        record,
        column,
      };
    },

    /* private controls of position */
    $_isPositionOutOfRangeInCells(position) {
      return (
        position < 0 ||
        this.$_grid_records.length * this.leavesColumns.length - 1 < position
      );
    },
    $_isPositionStartOfRowExcludeNumberColumn(position) {
      const colIndex = position % this.leavesColumns.length;

      if (this.visibleRowNumberColumn) {
        return colIndex === 1;
      } else {
        return colIndex === 0;
      }
    },
    $_isPositionEndOfRow(position) {
      const colIndex = position % this.leavesColumns.length;

      return colIndex === this.leavesColumns.length - 1;
    },
    $_activatePosition(position) {
      // check range
      if (this.$_isPositionOutOfRangeInCells(position)) {
        return;
      }

      // cannot activate number column
      if (this.$_isNumberColumnIndex(position % this.leavesColumns.length)) {
        return;
      }

      const rowIndex =
        (position - (position % this.leavesColumns.length)) /
        this.leavesColumns.length;
      const colIndex = position % this.leavesColumns.length;

      const el = this.$refs[`grid-cells-${rowIndex}-${colIndex}`][0].$vnode.elm;
      el.focus();
      el.scrollIntoView({ block: "nearest" });

      this.activePosition = position;
    },
    $_registerActivePosition() {
      this.registeredPosition = this.activePosition;
    },
    $_moveActiveCellToUp() {
      this.$_activatePosition(this.activePosition - this.leavesColumns.length);
    },
    $_moveActiveCellToDown() {
      this.$_activatePosition(this.activePosition + this.leavesColumns.length);
    },
    $_moveActiveCellToLeft() {
      // if start of row, cannot move to left (exclude number column)
      if (this.$_isPositionStartOfRowExcludeNumberColumn(this.activePosition)) {
        return;
      }

      this.$_activatePosition(this.activePosition - 1);
    },
    $_moveActiveCellToRight() {
      // if end of row, cannot move to right
      if (this.$_isPositionEndOfRow(this.activePosition)) {
        return;
      }

      this.$_activatePosition(this.activePosition + 1);
    },

    // event handlers
    $_onColumnClick(event, { column, columnIndex }) {
      if (
        event.target.className.split(" ").includes("f-grid-column-filter") ||
        event.target.className.split(" ").includes("f-grid-column-resizer")
      ) {
        event.preventDefault();
        return;
      }

      if (this.$_isNumberColumnIndex(columnIndex)) {
        this.toggleCurrentPageRowsSelection();

        return;
      }

      if (
        !column.field ||
        (column.columns &&
          Array.isArray(column.columns) &&
          0 < column.columns.length)
      ) {
        return;
      }

      if (this.sortable && column.sortable) {
        const directions = ["asc", "desc"];

        if (this.orders.length === 0) {
          this.orders.push({
            field: column.field,
            direction: directions[0],
          });
        } else {
          const targetIndex = this.orders.findIndex(
            (order) => order.field === column.field
          );
          if (targetIndex < 0) {
            if (event.ctrlKey) {
              this.visibleOrderPriority = event.ctrlKey;
              this.orders.push({
                field: column.field,
                direction: directions[0],
              });
            } else {
              this.visibleOrderPriority = false;
              this.orders = [
                {
                  field: column.field,
                  direction: directions[0],
                },
              ];
            }
          } else {
            const target = this.orders[targetIndex];
            if (!event.ctrlKey) {
              if (target.direction === "asc") {
                this.orders = [
                  {
                    ...target,
                    direction: "desc",
                  },
                ];
              } else if (target.direction === "desc") {
                this.orders = [];
              }
            } else {
              if (target.direction === "asc") {
                target.direction = "desc";
              } else if (target.direction === "desc") {
                this.orders.splice(targetIndex, 1);
              }
            }

            if (1 < this.orders.length) {
              this.visibleOrderPriority = event.ctrlKey;
            } else {
              this.visibleOrderPriority = false;
            }
          }
        }

        this.$emit("sorted", event, this.getOrdersAndFiltersAndPage());
      }
    },
    $_onColumnFilterClick(event, { column }) {
      if (this.filterable) {
        if (
          !column.filterable ||
          (column.columns &&
            Array.isArray(column.columns) &&
            0 < column.columns.length) ||
          !column.field ||
          !column.type
        ) {
          return;
        }

        const gridBoundingClientRect = this.$refs[
          "grid"
        ].getBoundingClientRect();
        const filterBoundingClientRect = event.target.getBoundingClientRect();

        // 필터 대화창이 그리드의 width 를 넘어가는 경우임
        if (
          gridBoundingClientRect.right <
          filterBoundingClientRect.left + 250
        ) {
          this.absoluteLeftGridColumnFilterDialog =
            filterBoundingClientRect.right - gridBoundingClientRect.left - 250;
        } else {
          this.absoluteLeftGridColumnFilterDialog =
            filterBoundingClientRect.left - gridBoundingClientRect.left;
        }

        this.visibleColumnFilterDialog = true;
        this.$nextTick(() => {
          this.$refs["grid-column-filter-dialog"].show({
            column,
            filter: this.filters.find(
              ({ field }) => field === column.field
            ) || {
              field: column.field,
              type: column.type,
            },
          });
        });
      }
    },
    $_onGridColumnResizerMouseDown(event, { column }) {
      const index = this.leavesColumns.findIndex(({ id }) => id === column.id);
      if (index < 0) {
        return;
      }

      this.resizeColumnController.index = index;
      this.resizeColumnController.startX =
        event.pageX - this.$refs["grid"].getBoundingClientRect().left;
      this.resizeColumnController.startWidth = this.$refs[
        `grid-columns-${column.id}`
      ][0].$el.offsetWidth;

      this.resizeColumnController.active = true;
    },
    $_onGridBodyKeypress(event) {
      let preventDefaulted = false;

      if (
        [
          "ArrowDown",
          "ArrowUp",
          "ArrowLeft",
          "ArrowRight",
          "Enter",
          "Tab",
        ].includes(event.key)
      ) {
        // 스크롤 이동이 발생하지 않도록 preventDefault 호출
        event.preventDefault();

        /*
          이동키를 이용한 선택자 이동시에는, 선택된 행/셀 데이터를 비워줌.
          이동키로 selectedRows/selectedCellIndexes 을 update 하여 이 값으로 선택자 랜더링을 할 경우, 전체 Cell 에 대한 랜더링을 다시하기 때문에 성능에 대한 이슈가 발생함.
          셀을 클릭할 때에만 selectedRows/selectedCellIndexes 를 update.
          임의로 selectedRows/selectedCellIndexes 를 get 할 시, activePosition 으로 selectedRows/selectedCellIndexes 를 갱신하여 그 값을 리턴해야 함.
         */
        this.$_flushSelectedRows();
        this.$_flushSelectedCellIndexes();

        if (event.key === "Tab") {
          this.$_moveActiveCellToRight();
          // tab 이동시에는 이전 position 을 저장하지 않음
          return;
        } else if (event.key === "Enter") {
          const baseCell =
            this.activePosition === this.registeredPosition
              ? this.getActiveCell()
              : this.$_getRegisteredCell();

          this.$emit(
            "cellEnter",
            {
              preventDefault: () => (preventDefaulted = true),
            },
            baseCell
          );

          if (preventDefaulted) {
            return;
          }

          this.$_activatePosition(
            baseCell.rowIndex * this.leavesColumns.length +
              baseCell.colIndex +
              this.leavesColumns.length
          );
        } else {
          switch (event.key) {
            case "ArrowDown":
              this.$_moveActiveCellToDown();
              break;
            case "ArrowUp":
              this.$_moveActiveCellToUp();
              break;
            case "ArrowLeft":
              this.$_moveActiveCellToLeft();
              break;
            case "ArrowRight":
              this.$_moveActiveCellToRight();
              break;
          }
        }

        this.$_registerActivePosition();
      }
    },
    $_onGridBodyClick(event) {
      if (!event.target.className.split(" ").includes("f-grid-column-filter")) {
        if (
          event?.path &&
          event.path.findIndex(
            ({ className }) => className === "f-grid-column-filter-dialog"
          ) < 0
        ) {
          event.preventDefault();
          this.visibleColumnFilterDialog = false;
        }
      }
    },
    $_onGridColumnResizerMouseMove(event) {
      const { active, index, startX, startWidth } = this.resizeColumnController;

      if (active) {
        const gridResizerGuide = this.$refs["grid-resizer-guide"];
        const targetHeaderColgroupColumn = this.$refs[
          "grid-header-colgroup-columns"
        ][index];
        const targetBodyColgroupColumn = this.$refs[
          "grid-body-colgroup-columns"
        ][index];
        const offset =
          event.pageX -
          this.$refs["grid"].getBoundingClientRect().left -
          startX;
        const newWidth = startWidth + offset;
        if (newWidth < (this.leavesColumns[index].minWidth || 16)) {
          return;
        }

        gridResizerGuide.style.left = `${startX + offset}px`;
        targetHeaderColgroupColumn.style.width = `${newWidth}px`;
        targetBodyColgroupColumn.style.width = `${newWidth}px`;
      }
    },
    $_onGridColumnResizerMouseUp() {
      this.resizeColumnController.active = false;
    },
    $_onGridCellMousedown(event, payload) {
      event.preventDefault();

      this.mousedownStartPosition =
        payload.rowIndex * this.leavesColumns.length + payload.colIndex;

      this.$_refreshSelectedCellIndexes();

      if (this.$_isNumberColumnIndex(payload.colIndex)) {
        this.toggleRowSelection(payload.rowIndex, this.enableMultiSelectRows);
        this.selectCell(payload.rowIndex, payload.colIndex + 1);
        this.enableMultiSelectRows && this.$_registerActivePosition();
      } else {
        this.selectRow(payload.rowIndex, this.enableMultiSelectRows && event.ctrlKey);
        this.selectCell(payload.rowIndex, payload.colIndex, this.enableMultiSelectCells && event.ctrlKey);
      }
    },
    $_onGridCellMouseover() {
      if (!this.mousedownStartPosition) {
        return;
      }
    },
    $_onGridCellMouseup() {
      this.mousedownStartPosition = null;
    },
    $_onGridCellClick(event, payload) {
      this.$emit("cellClick", event, payload);
    },
    $_onGridCellDoubleClick(event, payload) {
      this.$emit("cellDoubleClick", event, payload);
    },
    $_onGotoFirstPageClick(event) {
      if (this.currentPageNumber === 1) {
        event.preventDefault();
        return;
      }

      this.gotoPage(event, { page: 1 });
    },
    $_onPreviousPageClick(event) {
      if (this.currentPageNumber === 1) {
        event.preventDefault();
        return;
      }

      this.gotoPage(event, { page: this.currentPageNumber - 1 });
    },
    $_onPreviousRangeClick(event) {
      if (!this.hasPrevRange) {
        event.preventDefault();
        return;
      }

      this.gotoPage(event, { page: this.startPageNumber - this.rangeOfPages });
    },
    $_onPageClick(event, { page }) {
      this.gotoPage(event, { page });
    },
    $_onNextRangeClick(event) {
      if (!this.hasNextRange) {
        event.preventDefault();
        return;
      }

      this.gotoPage(event, { page: this.startPageNumber + this.rangeOfPages });
    },
    $_onNextPageClick(event) {
      if (this.currentPageNumber === this.lastPageNumber) {
        event.preventDefault();
        return;
      }

      this.gotoPage(event, { page: this.currentPageNumber + 1 });
    },
    $_onGotoLastPageClick(event) {
      if (this.currentPageNumber === this.lastPageNumber) {
        event.preventDefault();
        return;
      }

      this.gotoPage(event, { page: this.lastPageNumber });
    },
    $_onColumnFilterDialogConfirm(event, payload) {
      this.visibleColumnFilterDialog = false;

      if (this.filterable) {
        const beforeFilterIndex = this.filters.findIndex(
          (filter) => filter.field === payload.filter.field
        );

        if (beforeFilterIndex < 0) {
          this.filters.push({
            ...payload.filter,
          });
        } else {
          this.filters[beforeFilterIndex] = {
            ...this.filters[beforeFilterIndex],
            ...payload.filter,
          };
        }

        this.currentPageNumber = 1;
        this.$emit("filtered", event, this.getOrdersAndFiltersAndPage());
      }
    },
    $_onColumnFilterDialogClear(event, { column }) {
      this.visibleColumnFilterDialog = false;

      if (this.filterable) {
        const beforeFilterIndex = this.filters.findIndex(
          (filter) => filter.field === column.field
        );
        if (!(beforeFilterIndex < 0)) {
          this.filters.splice(beforeFilterIndex, 1);
        }

        this.currentPageNumber = 1;
        this.$emit("filtered", event, this.getOrdersAndFiltersAndPage());
      }
    },
    $_onGridBodyContentScroll(event) {
      this.visibleColumnFilterDialog = false;
      this.$refs["grid-header-content"].scrollLeft = event.target.scrollLeft;
      this.$refs["grid-body-content"].scrollLeft = event.target.scrollLeft;
    },
  },
};
</script>

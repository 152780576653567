<template>
  <th
    class="f-grid-column"
    v-bind="$attributes"
    v-on="$events"
    :class="_class"
    :style="_style"
  >
    <slot />
  </th>
</template>

<script>
export default {
  name: "GridColumn",
  props: {
    column: {
      type: Object,
      required: true,
    },
  },
  computed: {
    $attributes() {
      return Object.assign({}, this.$attrs, {
        rowspan: this.$attrs.rowspan || this.column.rowspan,
        colspan: this.$attrs.colspan || this.column.colspan,
      });
    },
    $events() {
      return Object.assign({}, this.$listeners, {});
    },
    _class() {
      let _class = {};
      if (this.column.class instanceof Function) {
        const result = this.column.class(this.column, this.isLeafColumn);
        if (Array.isArray(result)) {
          result.forEach((cl) => {
            _class[cl] = true;
          });
        } else if (typeof result === "object") {
          _class = result;
        } else if (typeof result === "string") {
          _class[result] = true;
        }
      } else if (Array.isArray(this.column.class)) {
        this.column.class.forEach((cl) => {
          _class[cl] = true;
        });
      } else if (typeof this.column.class === "object") {
        _class = this.column.class;
      } else if (typeof this.column.class === "string") {
        _class[this.column.class] = true;
      }

      _class["f-grid-column-leaf"] = this.isLeafColumn;
      _class["sortable"] =
        this.isLeafColumn && this.column.field && this.column.sortable;

      return _class;
    },
    _style() {
      if (this.column.style instanceof Function) {
        return this.column.style(this.column, this.isLeafColumn);
      } else {
        return this.column.style;
      }
    },
    isLeafColumn() {
      return !this.column.columns || !(0 < this.column.columns.length);
    },
  },
};
</script>

<style scoped></style>

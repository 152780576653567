import Queue from "@/utils/Queue";
import Stack from "@/utils/Stack";

export function getTableHeaderOfColumns(columns = []) {
  const rows = [];
  const leaves = getLeavesOfColumns(columns);

  // init => enqueue into a queue
  const queue = new Queue(columns.filter(({ visible = true }) => !!visible));

  // start
  while (!queue.isEmpty()) {
    const _columns = [];
    // dequeue all from queue
    while (!queue.isEmpty()) {
      _columns.push(queue.dequeue());
    }

    // A row of table header = calculate colspan and remove children
    const row = [];
    _columns.forEach((column) => {
      const colspan = calculateColspanOfColumn(column);
      column.colspan = 1 < colspan ? colspan : undefined;
      row.push(column);
    });

    // push row
    rows.push(row);

    // for next => enqueue all children into a queue
    _columns
      .filter(({ columns, visible = true }) => !!visible && !!columns)
      .forEach(({ columns }) => {
        columns.forEach((column) => {
          queue.enqueue(column);
        });
      });
  }

  rows.forEach((row, index) => {
    row.forEach((col) => {
      if (leaves.find((leaf) => leaf === col)) {
        col.rowspan = rows.length - index;
      }
    });
  });

  return rows;
}

export function calculateColspanOfColumn(column = {}) {
  if (!column.columns || !(0 < column.columns.length)) {
    return 1;
  }

  return column.columns.reduce(
    (prev, column) => prev + calculateColspanOfColumn(column),
    0
  );
}

export function getLeavesOfColumns(columns = []) {
  const leaves = [];

  // init => push into a stack
  const stack = new Stack(columns.filter(({ visible = true }) => !!visible));

  // start
  while (!stack.isEmpty()) {
    const column = stack.pop();
    if (column.columns) {
      column.columns
        .filter(({ visible = true }) => !!visible)
        .forEach((column) => {
          stack.push(column);
        });
    } else {
      leaves.push(column);
    }
  }

  return leaves.reverse();
}

import moment from "moment";
import { commonCodesGetComName } from "@/utils/commonCodes";

export const RESULT_ERROR = "#ERROR!";

export const FORMAT_DATE_YYYY_MM_DD = "yyyy-MM-dd"; // origin: yyyyMMdd
export const FORMAT_DATE_YYYY_MM = "yyyy-MM"; // origin: yyyyMM
export const FORMAT_DATE_YYYY = "yyyy"; // origin: yyyy
export const FORMAT_TIME_HH_mm_ss = "HH:mm:ss"; // origin: HHmmss
export const FORMAT_TIME_HH_mm = "HH:mm"; // origin: HHmm
export const FORMAT_DATETIME = "yyyy-MM-dd HH:mm:ss"; // origin: yyyy-MM-dd HH:mm:ss

export const FORMAT_TEL_CELLPHONE_NUMBER = "#_TEL_CELLPHONE_NUMBER"; // origin: 0100000000 / 01100000000, output: 0
export const FORMAT_TEL_TELEPHONE_NUMBER = "#_TEL_TELEPHONE_NUMBER"; // origin: 020000000 / 0200000000 / 07000000000
export const FORMAT_BUSINESS_NUMBER = "#_BUSINESS_NUMBER"; // origin: 0000000000
export const FORMAT_CORPORATION_NUMBER = "#_CORPORATION_NUMBER"; // origin: 0000000000000
export const FORMAT_MEMBER_NUMBER = "#_MEMBER_NUMBER"; // origin: 00000000
export const FORMAT_NUMBER = "#_NUMBER"; // origin: (Number)

export function getFormatter(rule) {
  switch (rule) {
    case FORMAT_DATE_YYYY_MM_DD:
    case FORMAT_DATE_YYYY_MM:
    case FORMAT_DATE_YYYY:
    case FORMAT_TIME_HH_mm_ss:
    case FORMAT_TIME_HH_mm:
    case FORMAT_DATETIME:
      return getDatetimeFormatter(rule);
    case FORMAT_TEL_CELLPHONE_NUMBER:
      return cellphoneNumberFormatter;
    case FORMAT_TEL_TELEPHONE_NUMBER:
      return telephoneNumberFormatter;
    case FORMAT_BUSINESS_NUMBER:
      return businessNumberFormatter;
    case FORMAT_CORPORATION_NUMBER:
      return corporationNumberFormatter;
    case FORMAT_MEMBER_NUMBER:
      return memberNumberFormatter;
    case FORMAT_NUMBER:
      return numberFormatter;
  }

  if (rule.startsWith("@_")) {
    return getCommonCodeFormatter(rule.substr("@_".length));
  }

  throw Error(`Invalid format rule: ${rule}`);
}

function getCommonCodeFormatter(groupCode) {
  return (data) => commonCodesGetComName(groupCode, data) || RESULT_ERROR;
}

function getDatetimeFormatter(format) {
  let originFormat = "";

  switch (format) {
    case FORMAT_DATE_YYYY_MM_DD:
      originFormat = "yyyyMMdd";
      break;
    case FORMAT_DATE_YYYY_MM:
      originFormat = "yyyyMM";
      break;
    case FORMAT_DATE_YYYY:
      originFormat = "yyyy";
      break;
    case FORMAT_TIME_HH_mm_ss:
      originFormat = "HHmmss";
      break;
    case FORMAT_TIME_HH_mm:
      originFormat = "HHmm";
      break;
    case FORMAT_DATETIME:
      originFormat = "yyyy-MM-dd HH:mm:ss";
      break;
    default:
      throw Error(`Invalid date format: ${format}`);
  }

  return (data) => {
    if (data === undefined || data === null) {
      return "";
    }

    if (data instanceof Date || typeof data === "number") {
      return moment(new Date(data)).format(format);
    } else if (moment.isMoment(data)) {
      return data.isValid() ? data.format(format) : RESULT_ERROR;
    } else if (typeof data === "string") {
      if (moment(data, format).isValid()) {
        return data;
      } else {
        const momentInstance = moment(data, originFormat);

        return momentInstance.isValid()
          ? momentInstance.format(format)
          : RESULT_ERROR;
      }
    } else {
      return RESULT_ERROR;
    }
  };
}

function cellphoneNumberFormatter(data) {
  if (data === undefined || data === null) {
    return "";
  }

  if (typeof data !== "string") {
    return RESULT_ERROR;
  }

  if (
    /^([\d]){3}-([\w*]){3}-([\d]){4}$/.test(data) ||
    /^([\d]){3}-([\w*]){4}-([\d]){4}$/.test(data)
  ) {
    return data;
  }

  if (/^([\d]){3}([\w*]){3}([\d]){4}$/.test(data)) {
    return data.replace(/^(\d{3})([\w*]{3})(\d{4})$/, "$1-$2-$3");
  }

  if (/^([\d]){3}([\w*]){4}([\d]){4}$/.test(data)) {
    return data.replace(/^(\d{3})([\w*]{4})(\d{4})$/, "$1-$2-$3");
  }

  return RESULT_ERROR;
}

function telephoneNumberFormatter(data) {
  if (data === undefined || data === null) {
    return "";
  }

  if (typeof data !== "string") {
    return RESULT_ERROR;
  }

  if (
    /^([\d]){2}-([\w*]){3}-([\d]){4}$/.test(data) ||
    /^([\d]){2}-([\w*]){4}-([\d]){4}$/.test(data) ||
    /^([\d]){3}-([\w*]){3}-([\d]){4}$/.test(data) ||
    /^([\d]){3}-([\w*]){4}-([\d]){4}$/.test(data)
  ) {
    return data;
  }

  if (/^([\d]){2}([\w*]){3}([\d]){4}$/.test(data)) {
    return data.replace(/^(\d{3})([\w*]{3})(\d{4})$/, "$1-$2-$3");
  }

  if (/^([\d]){3}([\w*]){3}([\d]){4}$/.test(data)) {
    return data.replace(/^(\d{3})([\w*]{3})(\d{4})$/, "$1-$2-$3");
  }

  if (/^([\d]){3}([\w*]){4}([\d]){4}$/.test(data)) {
    return data.replace(/^(\d{3})([\w*]{4})(\d{4})$/, "$1-$2-$3");
  }

  return RESULT_ERROR;
}

function businessNumberFormatter(data) {
  if (data === undefined || data === null) {
    return "";
  }

  if (typeof data !== "string") {
    return RESULT_ERROR;
  }

  if (/^([\w]){3}-([\w]){2}-([\w]){5}$/.test(data)) {
    return data;
  }

  if (/^([\w]){10}$/.test(data)) {
    return data.replace(/^([\w]{3})([\w]{2})([\w]{5})$/, "$1-$2-$3");
  }

  return RESULT_ERROR;
}

function corporationNumberFormatter(data) {
  if (data === undefined || data === null) {
    return "";
  }

  if (typeof data !== "string") {
    return RESULT_ERROR;
  }

  if (/^([\w]){6}-([\w]){7}$/.test(data)) {
    return data;
  }

  if (/^([\w]){13}$/.test(data)) {
    return data.replace(/^([\w]{6})([\w]{7})$/, "$1-$2");
  }

  return RESULT_ERROR;
}

function memberNumberFormatter(data) {
  if (data === undefined || data === null) {
    return "";
  }

  if (typeof data !== "string") {
    return RESULT_ERROR;
  }

  if (/^(\w{2})-(\w{4})-(\w{2})$/.test(data)) {
    return data;
  }

  if (/^([\w]){8}$/.test(data)) {
    return data.replace(/^([\w]{2})([\w]{4})([\w]{2})$/, "$1-$2-$3");
  }

  return RESULT_ERROR;
}

function numberFormatter(data) {
  if (data === undefined || data === null) {
    return "-";
  }

  if (typeof data === "number") {
    if (data === 0) {
      return "-";
    } else if (data === Math.floor(data)) {
      return data.toLocaleString(undefined, {
        minimumFractionDigits: 0,
      });
    } else {
      return data.toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    }
  }

  return RESULT_ERROR;
}

<template>
  <div>
    <div class="f-grid-column-filter-dialog-content">
      <div>{{ column.name }}</div>
      <div class="f-grid-filter">
        <ejs-dropdownlist
          cssClass="lookup-condition-dropdown"
          v-bind="filterConditionDropDownListProps"
          v-model="filter.condition"
          @change="onFilterConditionChange"
        />
        <template v-if="filter.type === 'string'">
          <input
            type="text"
            class="f-grid-filter-query"
            v-model="filter.values[0]"
            placeholder="입력"
            @keypress.enter="onConfirmClick"
          />
        </template>
        <template v-else-if="filter.type === 'number'">
          <template v-if="filter.condition === 'between'">
            From
            <input
              type="number"
              class="f-grid-filter-query"
              v-model="filter.values[0]"
              placeholder="입력"
              @keypress.enter="onConfirmClick"
            />
            <br />
            To
            <input
              type="number"
              class="f-grid-filter-query"
              v-model="filter.values[1]"
              placeholder="입력"
              @keypress.enter="onConfirmClick"
            />
          </template>
          <template v-else>
            <input
              type="number"
              class="f-grid-filter-query"
              v-model="filter.values[0]"
              placeholder="입력"
              @keypress.enter="onConfirmClick"
            />
          </template>
        </template>
        <template v-else-if="filter.type === 'date'">
          <template v-if="filter.condition === 'between'">
            From
            <input
              type="date"
              max="9999-12-31"
              class="f-grid-filter-query"
              v-model="filter.values[0]"
              placeholder="입력"
              @keypress.enter="onConfirmClick"
            />
            <br />
            To
            <input
              type="date"
              max="9999-12-31"
              class="f-grid-filter-query"
              v-model="filter.values[1]"
              placeholder="입력"
              @keypress.enter="onConfirmClick"
            />
          </template>
          <template v-else>
            <input
              type="date"
              max="9999-12-31"
              class="f-grid-filter-query"
              v-model="filter.values[0]"
              placeholder="입력"
              @keypress.enter="onConfirmClick"
            />
          </template>
        </template>
        <template v-else-if="filter.type === 'datetime'">
          <template v-if="filter.condition === 'between'">
            From
            <input
              type="datetime-local"
              max="9999-12-31T23:59:59"
              class="f-grid-filter-query"
              v-model="filter.values[0]"
              placeholder="입력"
              step="1"
              @keypress.enter="onConfirmClick"
            />
            <br />
            To
            <input
              type="datetime-local"
              max="9999-12-31T23:59:59"
              class="f-grid-filter-query"
              v-model="filter.values[1]"
              placeholder="입력"
              step="1"
              @keypress.enter="onConfirmClick"
            />
          </template>
          <template v-else>
            <input
              type="datetime-local"
              max="9999-12-31T23:59:59"
              class="f-grid-filter-query"
              v-model="filter.values[0]"
              placeholder="입력"
              step="1"
              @keypress.enter="onConfirmClick"
            />
          </template>
        </template>
        <template v-else-if="filter.type === 'boolean'">
          <!-- Nothing -->
        </template>
        <template v-else>
          <input
            type="text"
            class="f-grid-filter-query"
            v-model="filter.values[0]"
            placeholder="입력"
            @keypress.enter="onConfirmClick"
          />
        </template>
      </div>
    </div>
    <div class="f-grid-column-filter-dialog-footer">
      <button type="button" class="confirm" @click="onConfirmClick">
        적용
      </button>
      <button type="button" class="clear" @click="onClearClick">초기화</button>
    </div>
  </div>
</template>

<style scoped>
.f-grid-column-filter-dialog-content {overflow: hidden;padding: 15px 18px 14px;color: #666;font-size: 12px;line-height: 1.5;display: block;flex: 1 1 auto;box-sizing: border-box;background-color: #fff;}
.f-grid-column-filter-dialog-content > div {padding: 12px 18px;margin: -15px -18px -14px;}
.f-grid-column-filter-dialog-footer {border-top: 1px solid #e0e0e0;padding: 3px 9px;border-bottom-left-radius: 0;border-bottom-right-radius: 0;bottom: 0;display: block;right: 0;width: 100%;text-align: right;box-sizing: border-box;background-color: #fff;}
.f-grid-column-filter-dialog-footer button {padding: 5px 8px 3px 23px;margin: 3px;border-radius: 3px;background: #fff url("../../assets/images/common/button-icon-default.png") no-repeat left -84px;color: #000;font-size: 12px;box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.06);box-sizing: border-box;border: 1px #ccc solid;font-weight: 400;font-family: "돋움", Dotum, Arial, Verdana, sans-serif;-webkit-font-smoothing: antialiased;cursor: pointer;display: inline-block;-ms-flex-pack: center;justify-content: center;line-height: 1.143em;outline: none;text-align: center;text-decoration: none;text-transform: uppercase;-webkit-user-select: none;-ms-user-select: none;user-select: none;vertical-align: middle;white-space: nowrap;}
.f-grid-column-filter-dialog-footer button.confirm {border-color: #2e7458;background: #2e7458 url("../../assets/images/common/button-icon-confirm.png") no-repeat left 0;color: #fff;}
.f-grid-column-filter-dialog-footer button.confirm:hover {border-color: #174e35;background-color: #174e35;}
.f-grid-column-filter-dialog-footer button.clear {background-image: url("../../assets/images/common/button-icon-reset.png");}
.f-grid-column-filter-dialog-footer button.clear:hover {background-color: #f0f0f0;}
.f-grid-filter >>> .e-input-group {-webkit-box-sizing: border-box;box-sizing: border-box;padding: 2px 6px 1px;margin: 0;border: 1px solid #e0e0e0 !important;border-radius: 3px;background-color: #fff;color: #666;font-size: 12px;font-weight: 400;font-family: "돋움", Dotum, Arial, Verdana, sans-serif;}
.f-grid-filter >>> .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.f-grid-filter >>> .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after {background: none;}
.f-grid-filter >>> .e-input-group select {border: 0;height: 0;visibility: hidden;width: 0;outline: none;}
.f-grid-filter >>> .e-input-group .e-input {padding: 0 !important;margin: 0;border: none;color: #666;font-size: 12px;font-family: "돋움", Dotum, Arial, Verdana, sans-serif;border-radius: 3px;background-color: #fff;box-sizing: border-box;min-height: 18px;}
.f-grid-filter >>> .e-input-group .e-input-group-icon {margin: 0;min-width: 18px;min-height: 18px;}
.f-grid-filter >>> .e-input-group .e-input-group-icon:before {width: 9px;height: 5px;background-image: url("../../assets/images/common/input-arrow.png");content: "";}
.f-grid-filter .f-grid-filter-query {width: 100%;-webkit-box-sizing: border-box;box-sizing: border-box;margin: 9px 0 0 0;padding: 3px 7px 2px;border: 1px solid #e0e0e0;border-radius: 3px;background-color: #fff;color: #666;font-size: 12px;font-family: "돋움", Dotum, Arial, Verdana, sans-serif;}
.f-grid-filter .f-grid-filter-query:focus {border-color: #999;}
</style>

<script>
import moment from "moment";

export default {
  name: "GridColumnFilterDialog",
  data() {
    return {
      column: {},
      filter: {
        values: [],
      },
    };
  },
  computed: {
    stringFilterDataSource() {
      return [
        {
          comCode: "startsWith",
          comName: "입력 값으로 시작",
        },
        {
          comCode: "endsWith",
          comName: "입력 값으로 끝",
        },
        {
          comCode: "contains",
          comName: "입력 값 포함",
        },
        {
          comCode: "equals",
          comName: "같은 값",
        },
        {
          comCode: "notEquals",
          comName: "다른 값",
        },
      ];
    },
    numberFilterDataSource() {
      return [
        {
          comCode: "equals",
          comName: "입력 값과 동일",
        },
        {
          comCode: "notEquals",
          comName: "입력 값 제외",
        },
        {
          comCode: "greaterTanOrEquals",
          comName: "입력 값 이상",
        },
        {
          comCode: "lessThenOrEquals",
          comName: "입력 값 이하",
        },
        {
          comCode: "greaterThan",
          comName: "입력 값 초과",
        },
        {
          comCode: "lessThan",
          comName: "입력 값 미만",
        },
        {
          comCode: "between",
          comName: "입력 값 사이",
        },
      ];
    },
    dateFilterDataSource() {
      return [
        {
          comCode: "equals",
          comName: "입력 날짜와 동일",
        },
        {
          comCode: "notEquals",
          comName: "입력 날짜 제외",
        },
        {
          comCode: "greaterTanOrEquals",
          comName: "입력 날짜를 포함한 이후",
        },
        {
          comCode: "lessThenOrEquals",
          comName: "입력 날짜 포함한 이전",
        },
        {
          comCode: "greaterThan",
          comName: "입력 날짜보다 이후",
        },
        {
          comCode: "lessThan",
          comName: "입력 날짜보다 이전",
        },
        {
          comCode: "between",
          comName: "입력 날짜 사이",
        },
      ];
    },
    datetimeFilterDataSource() {
      return [
        {
          comCode: "equals",
          comName: "입력 일시와 동일",
        },
        {
          comCode: "notEquals",
          comName: "입력 일시 제외",
        },
        {
          comCode: "greaterTanOrEquals",
          comName: "입력 일시를 포함한 이후",
        },
        {
          comCode: "lessThenOrEquals",
          comName: "입력 일시 포함한 이전",
        },
        {
          comCode: "greaterThan",
          comName: "입력 일시보다 이후",
        },
        {
          comCode: "lessThan",
          comName: "입력 일시보다 이전",
        },
        {
          comCode: "between",
          comName: "입력 일시 사이",
        },
      ];
    },
    booleanFilterDataSource() {
      return [
        {
          comCode: "true",
          comName: "true",
        },
        {
          comCode: "false",
          comName: "false",
        },
      ];
    },
    filterDataSource() {
      switch (this.filter.type) {
        case "string":
          return this.stringFilterDataSource;
        case "number":
          return this.numberFilterDataSource;
        case "date":
          return this.dateFilterDataSource;
        case "datetime":
          return this.datetimeFilterDataSource;
        case "boolean":
          return this.booleanFilterDataSource;
      }

      return this.stringFilterDataSource;
    },
    filterConditionDropDownListProps() {
      return {
        allowFiltering: false,
        dataSource: this.filterDataSource,
        fields: { text: "comName", value: "comCode" },
      };
    },
  },
  methods: {
    show({ column, filter }) {
      this.column = {
        ...column,
      };

      const type = (typeof filter.type === "function"
        ? filter.type.name
        : filter.type
      ).toLowerCase();

      let defaultCondition = this.stringFilterDataSource[0].comCode;
      switch (type) {
        case "string":
          defaultCondition = this.stringFilterDataSource[0].comCode;
          break;
        case "number":
          defaultCondition = this.numberFilterDataSource[0].comCode;
          break;
        case "date":
          defaultCondition = this.dateFilterDataSource[0].comCode;
          break;
        case "datetime":
          defaultCondition = this.datetimeFilterDataSource[0].comCode;
          break;
        case "boolean":
          defaultCondition = this.booleanFilterDataSource[0].comCode;
          break;
      }

      const values =
        filter?.values?.map((value) => {
          if (value === undefined || value === null) {
            return undefined;
          }

          if (type === "date") {
            return moment(value, "YYYYMMDD").format("YYYY-MM-DD");
          } else if (type === "datetime") {
            return moment(value, "YYYY-MM-DD HH:mm:ss").format(
              "YYYY-MM-DDTHH:mm:ss"
            );
          } else {
            return value;
          }
        }) || [];
      this.filter = {
        field: filter?.field,
        condition: filter?.condition || defaultCondition,
        values,
        type,
      };
    },
    onFilterConditionChange(args) {
      if (args.value === "true" || args.value === "false") {
        this.filter.values[0] = args.value === "true";
      }
      if (!args.e) {
        return;
      }
      if (args.value !== "between") {
        this.filter.values[1] = undefined;
      }
    },
    onConfirmClick(event) {
      if (
        this.filter.values[0] === undefined ||
        this.filter.values[0] === null ||
        (this.filter.type !== "boolean" && this.filter.values[0].trim() === "")
      ) {
        this.$emit("clear", event, { column: this.column });
      } else {
        if (this.filter.type === "date") {
          this.filter.values = this.filter.values.map((value) =>
            value?.replace(/-/gi, "")
          );
        } else if (this.filter.type === "datetime") {
          this.filter.values = this.filter.values.map((value) =>
            value?.replace(/T/, " ")
          );
        } else if (this.filter.type === "string") {
          this.filter.values = this.filter.values.map((value) => value?.trim());
        }

        this.$emit("confirm", event, {
          column: this.column,
          filter: this.filter,
        });
      }
    },
    onClearClick(event) {
      this.$emit("clear", event, { column: this.column });
    },
  },
};
</script>

export default class Stack {
  #arr;

  constructor(arr) {
    this.#arr = arr;
  }

  push(item) {
    this.#arr.push(item);
  }

  pop() {
    return this.#arr.pop();
  }

  size() {
    return this.#arr.length;
  }

  isEmpty() {
    return this.#arr.length === 0;
  }
}
